import React, { useState, useRef, useEffect } from 'react';

import { adminaxios } from "../../../../axios";
import { NewCustomerListsRenewChangePlanModal } from "../../customermanagement/NewCustomerListsRenewChangePlanModal";

const RenewChangePlanModalCommon = props => {

  const [serviceObjData, setServiceObjData] = useState([]);

  // change plan state
  const [changeplan, setChangeplan] = useState([]);
  const [changeplanListBkp, setChangeplanListBkp] = useState([]);
  // service plan state
  const [serviceplanobj, setServiceplanobj] = useState([]);
  const [serviceplanobjbkp, setServiceplanobjbkp] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [selectID, setSelectID] = useState("");
  const ref = useRef();
  const box = useRef(null);
  const searchInputField = useRef(null);

  //refresh page
  const RefreshHandler = () => {
    setRefresh((prevValue) => prevValue + 1);
    if (searchInputField.current)
      searchInputField.current.value = "";
  };

  const changePlanSubmit = ({ ID, area }) => {
    adminaxios
      .get(`accounts/area/${area}/otherplans/${ID}`)
      .then((res) => {
        setChangeplan(res.data);
        setChangeplanListBkp(res.data);
      })
      .catch((error) => {
        console.log(error);
        // toast.error("Something went wrong", {
        //   position: toast.POSITION.TOP_RIGHT,
        //   autoClose: 1000,
        // });
      });
  };
  const dataSubmit = ({ ID, customerID, area }) => {
    // setSelectID(customerID);
    adminaxios
      .get(`accounts/loggedin/${area}/plans/${ID}`)
      .then((res) => {
        setServiceplanobj(res.data);
        setServiceplanobjbkp(res.data);
      })
      .catch(function (error) {
        console.log(error);
        //  toast.error("Something went wrong", {
        //    position: toast.POSITION.TOP_RIGHT,
        //    autoClose: 1000,
        //  });
      });
  };

  const changePlanClickHandler = (service_plan_id, row_id, row_area_id) => {
    dataSubmit({ ID: service_plan_id, customerID: row_id, area: row_area_id });
    changePlanSubmit({
      ID: service_plan_id,
      area: row_area_id,
    });
  };

  useEffect(() => {
    const { service_plan, area_id, id } = JSON.parse(sessionStorage.getItem("customerInfDetails"));
    setSelectID(id)
    changePlanClickHandler(service_plan, id, area_id);
  }, [])



  return (
    <div>
      <NewCustomerListsRenewChangePlanModal
      fetchComplaints={props.fetchComplaints}
        isRenewChangePlanModalOpen={props.isRenewChangePlanModalOpen}
        toggleRenewChangePlanModalOpen={props.toggleRenewChangePlanModalOpen}
        serviceObjData={serviceObjData}
        setServiceObjData={setServiceObjData}
        RefreshHandler={RefreshHandler}
        selectID={selectID}
        changeplan={changeplan}
        setChangeplan={setChangeplan}
        changeplanListBkp={changeplanListBkp}
        setChangeplanListBkp={setChangeplanListBkp}
        serviceplanobj={serviceplanobj}
        setServiceplanobj={setServiceplanobj}
        serviceplanobjbkp={serviceplanobjbkp}
        setServiceplanobjbkp={setServiceplanobjbkp}
        setUpdateInfoCount={props.setUpdateInfoCount}
      />
    </div>
  );
};

RenewChangePlanModalCommon.propTypes = {

};

export default RenewChangePlanModalCommon;