import React, { useState, useEffect } from "react";
import MUIButton from "@mui/material/Button";
import { Row, Col, Input, Button, Label, Spinner,FormGroup } from "reactstrap"
import { adminaxios, customeraxios } from "../../../../axios";
import { toast } from "react-toastify";
const AreaShiffting = (props) => {
    const [getAreas, setGetAreas] = useState([])
    const [getData, setGetData] = useState()
    const [loader, setLoader] = useState(false)
    const [paymentoption, setsetPaymentOPtion] = useState()
    const handleAreaChange = (event) => {
        setGetData((getData) => ({
            ...getData,
            [event.target.name]: event.target.value,
        }));
    }
    // area list api
    useEffect(() => {
        adminaxios.get(`franchise/areas/${props?.profileDetails?.area?.franchise?.id}/${props?.profileDetails?.area?.id}`)
            .then((response) => {
                const formattedAreas = response.data.map((areaObj) => areaObj.area);
                setGetAreas(formattedAreas);
            })
    }, [])

    // area to area shifting  post api
    const areaShifting = () => {
        const areaData = { ...getData }
        console.log(areaData,"areaData");

        areaData.amount = props?.profileDetails?.area?.franchise?.shifting_charges;
        setLoader(true)
        customeraxios.patch(`customers/off/areashift/${props?.profileDetails?.id}`, areaData).then((res) => {
            setLoader(false)
            props.AreaShifftingModal();
            props.fetchComplaints()
            window.location.reload();
            toast.success("Your area changed successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
        }).catch((error) => {
            setLoader(false)
            toast.error("Something went wrong", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            })
        })
    }

    return (
        <>
            <Row>
                <Col>
                    <Label className="kyc_label">Franchise</Label>
                    <Input value={props?.profileDetails?.area?.franchise?.name}
                        disabled={true}
                    />
                </Col>
                <Col>
                    <Label className="kyc_label">Area</Label>
                    <Input value={props?.profileDetails?.area?.name}
                        disabled={true}
                    />
                </Col>
                <Col>
                    <Label className="kyc_label">Area To</Label>
                    <Input type="select" name="new_area" onChange={handleAreaChange}>
                        <option style={{ display: "none" }}></option>
                        {getAreas?.map((areas) => (
                            <option key={areas.id} value={areas.id}>
                                {areas.name}
                            </option>
                        ))}
                    </Input>

                </Col>
                <Col>
                    <Label className="kyc_label">Shifting Charges</Label>
                    <Input value={props?.profileDetails?.area?.franchise?.shifting_charges}
                        disabled={true}
                    />
                </Col>
            </Row>
            <br />
            <Row>
                    <Col sm="4" >
                        <FormGroup>
                            <div className="input_wrap">
                                <Label className="kyc_label"> Payment Method </Label>
                                <Input
                                    type="select"
                                    name="payment_method"
                                    className="form-control digits not-empty"
                                    onChange={(event) => {
                                        handleAreaChange(event);
                                        setsetPaymentOPtion(event.target.value);
                                    }}
                                >
                                    <option value="" style={{ display: "none" }}></option>
                                    <option value="GPAY">Google Pay</option>
                                    <option value="PHNPE">PhonePe</option>
                                    <option value="BNKTF">Bank Transfer</option>
                                    <option value="CHEK">Cheque</option>
                                    <option value="CASH">Cash</option>
                                    <option value="PAYTM">PayTM</option>
                                </Input>

                                {/* <span className="errortext">
                                    {errors.payment_method && "Field is required"}
                                </span> */}
                            </div>
                        </FormGroup>
                    </Col>
                    <Col
                        sm="4"
                        hidden={paymentoption != "BNKTF"}
                    >  <Label className="kyc_label"
                    >  Bank Reference No.   </Label>
                        <Input
                            onChange={handleAreaChange}
                            name="bank_reference_no"
                            className="form-control"
                            type="text"
                        />

                        {/* <span className="errortext">
                            {errors.bank_reference_no}
                        </span> */}
                    </Col>
                    <Col
                        sm="4"
                        hidden={paymentoption != "GPAY" && paymentoption != "PHNPE"}
                    >
                        <Label className="kyc_label">UTR No. </Label>
                        <Input
                            onChange={handleAreaChange}
                            name="upi_reference_no"
                            className="form-control"
                            type="text"
                        />
{/* 
                        <span className="errortext">
                            {errors.upi_reference_no}
                        </span> */}
                    </Col>
                    <Col
                        sm="4"
                        hidden={paymentoption != "CHEK"}
                    >  <Label className="kyc_label"> Cheque No. </Label>
                        <Input
                            onChange={handleAreaChange}
                            name="check_reference_no"
                            className="form-control"
                            type="text"
                        />

                        {/* <span className="errortext">
                            {errors.check_reference_no}
                        </span> */}
                    </Col>
                </Row>
                <br />
            <hr />
            <Row style={{ textAlign: "end" }}>
                <Col >

                    <Button
                        variant="contained"
                        id="update_button"
                        onClick={areaShifting}
                        style={{ color: "white" }}
                        disabled={loader}
                    >
                        {loader ? <Spinner size="sm"> </Spinner> : null}
                        Submit
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <MUIButton variant="outlined"
                        size="medium"
                        className="cust_action"
                        onClick={() => {
                            props.AreaShifftingModal();
                        }}
                    >
                        Cancel
                    </MUIButton>
                </Col>
            </Row>
            <br />
        </>
    )
}
export default AreaShiffting