import React, { useEffect} from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../../../../mui/accordian";
import Typography from "@mui/material/Typography";


const CPEInfo = ({}) => {
 

  const [expanded, setExpanded] = React.useState("panel7");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Accordion
      style={{
        borderRadius: "15px",
        boxShadow: "0 0.2rem 1rem rgba(0, 0, 0, 0.15)",
        flex: "0 0 100%",
      }}
      expanded={expanded === "panel7"}
      onChange={handleChange("panel7")}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="online-session-info"
      >
        <Typography
          variant="h6"
          className="customerdetailsheading"
        >
         CPE Information
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ lineHeight: "3rem" }}>
        
      </AccordionDetails>
    </Accordion>
  );
};

export default CPEInfo;
