import React from "react";
import LOGINIMG from "../assets/images/login/loginimg.png";
const CONTENT = () => {
  return (
    <>

      <h1
        style={{
          position: "relative",
          marginTop: "80px",
          fontFamily: "Open Sans",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "50px",
          color: "#003B8D",
        }}
      >
        Welcome!
      </h1>
      <div>
        <h5 className="login_text">
          We would like to introduce ourselves as Sparkradius. We have established in the year 2021 providing Internet services as 'B' class ISP license. We use the state of the art technology from the leaders in the industry to ensure that we stay on the leading edge of Internet technology.

          <br /><br/>
          We are a uniquely positioned internet service provider (ISP) in being able to provide both wireless broadband internet as well as FIBER and other fixed-line broadband solutions. Being able to deliver the mobility benefits of truly unwired/wireless internet means that whether inside or outside the home or office we can keep you secure, online and connected.
        </h5>
      </div>
      <br />
      <div>
        <img src={LOGINIMG} className="loginimg" />
      </div>
    </>
  )
}

export default CONTENT;
